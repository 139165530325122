<template>
  <div class="mt-6 flex">
    <button
      class="border-l rounded-l-xl"
      :class="courseAllClasses"
      @click="courseTypeChanged(null)"
    >
      All <span class="ml-1">{{ totalCourseCount }}</span></button
    ><button
      v-for="(item, index) in courseTypeFilters.sortedCourseTypes"
      :key="`btn_courseType_${index}`"
      :class="courseTypeClasses(item, index)"
      :disabled="item.count < 1"
      @click="courseTypeChanged(item)"
    >
      {{ item.title }}
      <span class="ml-1">{{ item.count }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import useCourseTypeFilters, {
  CourseType,
  CourseTypeFiltersProps
} from '~/composables/useCourseTypeFilters'

const props = withDefaults(defineProps<CourseTypeFiltersProps>(), {})

const emit = defineEmits(['selected-course-type-changed'])

const courseTypeChanged = async (courseType: CourseType | undefined | null) => {
  await courseTypeFilters.updateQuery(courseType)
  emit('selected-course-type-changed', courseType)
}

const courseTypeEmit = (courseType: CourseType | undefined | null) => {
  emit('selected-course-type-changed', courseType)
}

const courseTypeFilters = useCourseTypeFilters(props, courseTypeEmit)

const courseAllClasses = computed(() => {
  return [
    !props.selectedCourseType
      ? `bg-brand-${props.courseAreaColour}-300 text-black`
      : 'text-gray-700'
  ]
})

const courseTypeClasses = (item: CourseType, index: number) => {
  const isActive =
    props.selectedCourseType && props.selectedCourseType.title === item.title
  return [
    {
      'text-gray-400 cursor-not-allowed': item.count < 1
    },
    isActive ? `bg-brand-${props.courseAreaColour}-300 text-black` : '',
    item.count > 0 && !isActive ? 'text-gray-700' : ''
  ]
}
</script>

<style lang="postcss" scoped>
button {
  @apply border-t border-b border-gray-400 px-4 py-2 text-base font-bold;
}

button:last-of-type {
  @apply rounded-r-xl border-r;
}
</style>
